import React, { useEffect } from "react";
import AppLayout from "./layout";
import Container from "../components/container";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth-service";
import BuyByeService from "../services/buybye-service";
import queryString from "query-string";
import LOGO from "../components/logo";
import LocaleContext from "../context/LocaleContext";
import Chip from "@material-ui/core/Chip";
import CodeInput from "../components/codeInput";

const useStyles = makeStyles((theme) => ({
  cardNumber: {
    marginBottom: "0px",
  },
  creditCard: {
    borderBottom: "1px solid #eee",
    display: "flex",
    paddingBottom: "10px",
    justifyContent: "revert",
  },
  marginBottom: {
    marginBottom: "12vh",
  },
  mt1: {
    margin: theme.spacing(0.5),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));
const Address = ({ data }) => (
  <div>
    <address className="header__address" style={{ fontSize: "1.4em" }}>
      <i className="fas fa-map-marker-alt" />
      {data.subtitle}
    </address>
  </div>
);
const HeaderTitle = ({ data }) => (
  <h1 className="header__title">{data.title}</h1>
);

const HeaderTop = ({ data }) => (
  <div className="header__top">
    <HeaderTitle data={data} />
  </div>
);
const HeaderItem = ({ data }) => {
  return (
    <div className="header__item">
      <HeaderTop data={data} />
      <Address data={data} />
    </div>
  );
};
const RecieptsCard = ({ classes, data, id }) => {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const [stock, setStock] = React.useState(data.stock.total);


  const diff = (data.maxStock - data.stock.total) / data.maxStock;
  console.log(diff);

  let alert = "";
  let background;
  let color = "white";
  if (diff >= 0.6) {
    alert = "High";
    background = "#dc29299e";
  } else if (diff > 0.2 && diff < 0.6) {
    alert = "Medium";
    background = "#f1ca0b";
  } else {
    alert = "Low";
    background = "#2dd02d";
  }

  const updateStock = async(value,data) =>{
    let _value = value.target.value
    setStock(_value);
    console.log(_value)
    console.log(data)
    await BuyByeService.updateStock({
      productID:data._id,
      amount:value.target.value,
      override:true,
      row:data.row[0],
      shelf:data.shelf
    })
    

  }

  return (
    <div className={classes.creditCard}>
      <div style={{ marginRight: "1em", marginTop: "1em" }}>
        <img src={data.product.imageUrl} alt="" style={{ width: "4rem" }} />
      </div>
      <div>
        <Typography className={clsx(classes.mt1)} color="primary">
          {data.product.name}
        </Typography>
        <div style={{display:"flex"}}>
          <input
          onChange={(value) =>{ updateStock(value,data)}}
            type="number"
            value={stock}
            style={{
              height: "1.2em",
              width: "2em",
              padding: "1px",
              marginTop: "0.6em",
              textAlign: "center",
              marginRight: "2px",
              fontSize: "1.5em",
            }}
          />
          <h3 style={{margin:"10px 0px",fontSize:"1.5em"}}>/{data.maxStock}</h3>
        </div>
        <div className={clsx(classes.mt1)}>
          {`Alert : `}
          <Chip label={alert} style={{ background: background, color }}></Chip>
        </div>
      </div>
    </div>
  );
};
const Reciepts = (props) => {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const [cards, setCards] = React.useState(undefined);
  const [refresh, setRefresh] = React.useState(undefined);
  const parsed = queryString.parse(history.location.search);
  //const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    async function main() {
      if (parsed.machine) {
        let res = await BuyByeService.getProductsMachine(parsed.machine);
        if (res.success) {
          console.log(res.result);

          const shelfs = {};
          res.result.products.forEach((p) => {
            if (p.shelf in shelfs) {
              shelfs[p.shelf].push(p);
            } else {
              shelfs[p.shelf] = [p];
            }
          });
          setCards(shelfs);
        }
      }
    }
    main();
  }, [history, refresh]);

  const classes = useStyles();

  const sendCode = async (code) => {
    console.log(code);
    history.push("/receipts?machine=" + code);
    setRefresh(true);
  };

  if (!cards) {
    return (
      <AppLayout
        title={<LOGO></LOGO>}
        bottomNav
        style={{ overflowY: "scroll" }}
      >
        <h2 style={{ margin: 20 }}>Insert the machine code.</h2>
        <CodeInput sendCode={sendCode}></CodeInput>
        <Container>
          <img
            src="https://res.cloudinary.com/reckon/image/upload/v1593016575/reckon-platform/logos/File.jpg"
            alt="Open Cabinet"
            style={{ width: "100%", height: "80%" }}
          />
        </Container>
      </AppLayout>
    );
  }

  const items = [];

  Object.keys(cards).forEach((key) => {
    items.push(
      <Container
        className="Header"
        style={{ margin: "1em 0em", fontSize: "1.4em" }}
      >
        <HeaderItem data={{ subtitle: `Shelf:${key}` }}></HeaderItem>
      </Container>
    );

    function dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    }
    let sorted = cards[key].sort(dynamicSort(["row"][0]));
    console.log(sorted);
    sorted = sorted.filter(prod => prod.active);
    sorted.map((card) => {
      items.push(
        <Container className="Header">
          <RecieptsCard
            key={card._id + Math.floor(Math.random() * (125 - 5) + 5)}
            id={card._id}
            classes={classes}
            data={card}
          />
        </Container>
      );
    });
  });

  return (
    <AppLayout title={<LOGO></LOGO>} bottomNav style={{ overflowY: "scroll" }}>
      <div className={clsx(classes.marginBottom)}>
        <div className={(classes.mt1, classes.marginTop)}>{items}</div>
      </div>
    </AppLayout>
  );
};
export default Reciepts;
