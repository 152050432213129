import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChooseLocale from "./chooseLocale";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "95%",
  },
  btnFull: {
    width: "100%",
    marginTop: "2vh",
    color: "white",
  },
}));
export default function ProfileForm(props) {
  const classes = useStyles();
  const handleChange = props.onChange;
  const logout = props.logout;
  return (
    <div>
      <div className={clsx()}>
        <h1 className={"header__address"}>Profile</h1>
      </div>

      <TextField
        label="Name"
        value={props.name}
        onChange={handleChange("name")}
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
      />
      <TextField
        label="Email"
        value={props.email}
        onChange={handleChange("email")}
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
      />
      <TextField
        label="Phone Number"
        value={props.phone}
        disabled
        onChange={handleChange("phone")}
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
      />
      <TextField
        label="(NIF) - VAT Number "
        value={props.nif}
        type="number"
        onChange={handleChange("nif")}
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
        style={{ display: "None" }}
      />
      <ChooseLocale lable={true}></ChooseLocale>
      <Button
        variant="contained"
        color="secondary"
        onClick={logout}
        className={clsx(classes.btnFull)}
        endIcon={<ExitToAppIcon />}
      >
        Logout
      </Button>
    </div>
  );
}
